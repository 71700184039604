import React, { Component } from "react";
import ajay from "../../src/images/me.png";

class UserInfo extends Component {
  render() {
    return (
      <aside className="note">
        <div className="container note-container">
          <div className="flex-author">
            <h2>A note from the author</h2>
            <p>
              Hi! I'm Ajay. I'm a Data Architect specializing in digital data
              sources. I write free content aimed at helping people understand and
              optimize their digital properties. If you enjoy my written work and would like an in-depth understanding of the data world
              consider signing up for 
              <strong>
                <mark>
                the junior mentorship program 
                </mark> 
              </strong>
               by checking out their
              <strong>
                <mark>
                  project based 
                  </mark>
                </strong>
                 learning paths.
              
            </p>

            <a
              href="https://www.juniormentorship.com/about"
              className="patreon-button"
              target="_blank"
            >
              Junior Mentorship
            </a>
            <a
              className="donate-button"
              href="https://ko-fi.com/ajayajaal"
              target="_blank"
            >
              Coffee
            </a>
          </div>
          <div className="flex-avatar">
            <img className="avatar" src={ajay} />
          </div>
        </div>
      </aside>
    );
  }
}

export default UserInfo;
